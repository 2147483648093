import { combineReducers } from 'redux'


import contactsReducer from './contacts.js'
import pagesReducer from './pages.js'
import menuReducer from './menu.js'
import contactsFormReducer from './contactsForm.js'
import mainReducer from './mainPage.js'



const applicationReducer = combineReducers(
{
	contactsReducer,
	menuReducer,
	contactsFormReducer,
	mainReducer,
	pagesReducer
}
)


export default applicationReducer