import React from 'react';
// import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route} from 'react-router-dom'; 
import { render } from 'react-dom'
import { Link } from 'react-router-dom'; 

import './index.scss';
import MainWrap from './components/main/MainWrap';
import Router from './components/router/Router';
import Contactswrap from './components/contacts/ContactsWrap';
import HeaderWrap from './components/templates/header/HeaderWrap';
import PagesWrap from './components/pages/PagesWrap';
import * as serviceWorker from './serviceWorker';

import { Provider } from 'react-redux'
import store from './store/index.js'

import ApplicationService from './services/ApplicationService';
import { ApplicationServiceProvider} from './containers/application-service';

const appService = new ApplicationService();

render(
	<Provider store={store}>	
<ApplicationServiceProvider value = {appService}>
<BrowserRouter>

<div className="site">
        <div className="content">
<HeaderWrap />
<Router />


{ /* <Switch>
 <Route exact path='/' component={MainWrap}/>
 <Route path='/contacts' component={Contactswrap}/>
 <Route path='/:id' component={PagesWrap}/>
 </Switch>

*/ }

	

	</div>


<footer className="site-footer">
<span>Copyright© 2018. All rights reserved.</span>
</footer>

</div>
</BrowserRouter>
	</ApplicationServiceProvider>
	</Provider>,
	document.getElementById('root')
)


serviceWorker.unregister();