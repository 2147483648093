import React, { Component } from 'react'

import ContactsContent from '../../containers/contacts/ContactsContent';
import ContactsFormMain from '../../containers/contacts/ContactsFormMain';

export default class ContactsWrap extends Component {

    render(){

        return(
<div>
<ContactsContent />
<ContactsFormMain />
</div>
        )     
    }    

}

