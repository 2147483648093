 import constants from '../keymirror/index.js'



const initialState = [];
 

const mainReducer = (state = initialState, action) => {

if(action.type === constants.RECIVE_MAIN_INFO){
    return state.innerHTML = action.main
}	

return state

}



export default mainReducer