 import constants from '../keymirror/index.js'



const initialState = [];
 

const menuReducer = (state = initialState, action) => {

if(action.type === constants.RECIVE_MENU){
    return state.innerHTML = action.menu
}	

return state

}



export default menuReducer