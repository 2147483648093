import React, { Component } from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { reciveMenu } from '../../../actions/menu/menu.js'

import MenuInfo from '../../../components/templates/menu/MenuInfo';

import { wrapContainer } from '../../../containers/wrap-containers';









class MenuContent extends Component {
  constructor() {
    super();

    this.state = {
      menuInfo: [],
      isLoaded: false
    };

  }

  componentDidMount() {

    this.setState({
      menuInfo: [],
      isLoaded: false
   })

   const { ApplicationService } = this.props;
   ApplicationService.getMenu().then((body) => {
    this.props.reciveMenu(body.items)    
     this.setState({
       isLoaded: true,
       menuInfo: this.props.menuContent,
     }) 
          });
   
      }	 
          
          


    render(){
let { menuInfo, isLoaded } = this.state;
 
        return(
          
          <div className="header__menu">
<MenuInfo menu={menuInfo} />
</div>

        )     
    }    

}

const mapStateProps = (state) => {
    return{
    menuContent: state.menuReducer
    } 
    }
    const mapDispatchProps = (dispatch) =>{  
    return {
    reciveMenu: bindActionCreators (reciveMenu, dispatch)
      }
    }
    
    export default wrapContainer()(connect(mapStateProps, mapDispatchProps)(MenuContent))