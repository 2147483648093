import React, { Component } from 'react'

export default class HeaderPopup extends Component {

    constructor() {
        super();
        
        this.state = {
            modalStatus: false
          }; 
            
    }
    componentDidMount() {
  document.addEventListener('mousedown', this.controlClick, false);      
    }
    componentWillUnmount(){
 document.removeEventListener('mousedown', this.controlClick, false);       
    }
controlClick = (e) => {
    if(this.state.modalStatus){
if(this.formStatus.contains(e.target)){
    return;
}
this.setState((state) => {
    return{    
    modalStatus: !state.modalStatus 
    } 
    })
}
    }


showModal = (formStatus) => {
        console.log(formStatus.id);
        this.setState((state) => {
        return{    
        modalStatus: !state.modalStatus 
        } 
        })     
        }
closeModal = () => {  
        this.setState((state) => {
        return{    
        modalStatus: !state.modalStatus 
        } 
            })    
            }
  
    

    render(){
 const { modalStatus } = this.state 
 console.log(modalStatus);
        return(

<div className="header__popup">
<button id="popup-1" className="popup" onClick={this.showModal.bind(this)}>Button</button>
<div className="modal-wrapper popup-1" style={{display: modalStatus ? 'block' : 'none' }}>
<div className="modal-table">
<div className="modal-table__cell">
<div ref={formStatus => this.formStatus = formStatus} className="modal-block">
<div className="close-modal" onClick={this.closeModal.bind(this)}>x</div>
<p>header-111</p>
</div>
</div>
</div>
</div>
</div>
        )     
    }    

}

