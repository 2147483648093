import React, { Component } from 'react'
import './HeaderWrap.scss';
import HeaderPopup from './HeaderPopup';
import MenuContent from '../../../containers/templates/menu/MenuContent';

export default class HeaderWrap extends Component {

    render(){
        return(
<header className="site-header">         
<div className="header-wrapper">
<HeaderPopup />
<MenuContent />
</div>
</header>   
        )     
    }    

}

