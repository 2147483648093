import React, { Component } from 'react'

import PagesContent from '../../containers/pages/PagesContent.js';

export default class PagesWrap extends Component {

    render(){

        return(
<div className="main">
<PagesContent />
</div>
        )     
    }    

}

