import React, { Component } from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { reciveContacts } from '../../actions/contacts.js'

import ContactsInfo from '../../components/contacts/ContactsInfo';

import { wrapContainer } from '../../containers/wrap-containers';









class ContactsContent extends Component {
  constructor() {
    super();

    this.state = {
      contactsInfo: [],
      titlePage: '', 
      isLoaded: false
    };

  }

  componentDidMount() {

    this.setState({
      contactsInfo: [],
      isLoaded: false
   })

   const { ApplicationService } = this.props;
   const dataContacts = ApplicationService.getOneNews().then((body) => {
    this.props.reciveContacts(body)    
     this.setState({
       isLoaded: true,
       contactsInfo: this.props.contactsContent,
       titlePage: body[0].title.rendered  
     }) 
          });
   
      }	 
          
          


    render(){
let { contactsInfo, titlePage, isLoaded } = this.state;
 
        return(
          
          <div>
<ContactsInfo contacts={contactsInfo} contactsTitle={titlePage}/>
</div>

        )     
    }    

}

const mapStateProps = (state) => {
    return{
    contactsContent: state.contactsReducer
    } 
    }
    const mapDispatchProps = (dispatch) =>{  
    return {
    reciveContacts: bindActionCreators (reciveContacts, dispatch)
      }
    }
    
    export default wrapContainer()(connect(mapStateProps, mapDispatchProps)(ContactsContent))