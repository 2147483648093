import React, { Component } from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { recivePages } from '../../actions/pages/pages.js'

import PagesInfo from '../../components/pages/PagesInfo';

import { wrapContainer } from '../../containers/wrap-containers';









class PagesContent extends Component {
  constructor() {
    super();

    this.state = {
      pagesInfo: [],
      titlePage: '', 
      isLoaded: false
    };

  }

  componentDidMount() {

  let pathName = window.location.pathname;
  const slug = pathName.replace(/\/wp/g, "");  

   const { ApplicationService } = this.props;
   ApplicationService.getPagesInfo(slug).then((body) => {
    if(body[0]){
    this.props.recivePages(body)    
     this.setState({
       isLoaded: true,
       pagesInfo: this.props.pagesContent,
     //  titlePage: body[0].title.rendered  
     }) 

    }else{
      window.location='https://blog.vorzelski.com.ua';
     } 
          });

         
   
      }	 
          
          


    render(){
let { pagesInfo, titlePage, isLoaded } = this.state;
 
        return(
          
          <div>
<PagesInfo pages={pagesInfo} pagesTitle={titlePage}/>
</div>

        )     
    }    

}

const mapStateProps = (state) => {
    return{
      pagesContent: state.pagesReducer
    } 
    }
    const mapDispatchProps = (dispatch) =>{  
    return {
    recivePages: bindActionCreators (recivePages, dispatch)
      }
    }
    
    export default wrapContainer()(connect(mapStateProps, mapDispatchProps)(PagesContent))