import React, { Component } from 'react'
import Parser from 'html-react-parser';
import MetaTags from 'react-meta-tags';

import './PagesWrap.scss';

export default class PagesInfo extends Component {


  
  render(){
const pagesInfo = this.props.pages;
let title = this.props.pagesTitle; 



    return(    
    <div className="page-info">
<MetaTags>
    <title>{title}</title>
    <meta name="description" content={`${title} - page`} />
    <meta property="og:title" content={title} />
    <meta property="og:image" content="" />
    </MetaTags> 

{pagesInfo.map(pages => {
return <div key={pages.id}>
  <h1>{pages.title.rendered}</h1>
  <div>{Parser(String(pages.content.rendered))}</div>
</div>
})

  }


    </div>
          )     
  }    
}