import React, { Component } from 'react';
import './Router.scss';


import Contactswrap from '../../components/contacts/ContactsWrap';
import MainWrap from '../../components/main/MainWrap';
import PagesWrap from '../../components/pages/PagesWrap';

export default class Router extends Component {   

  renderSwitch(param) {
  switch(param) {
  case '/contacts/':
  case '/contacts':  
  return <Contactswrap />
  case '/':
  case '':  
  return <MainWrap />
  default:
  return <PagesWrap />
  }
  }

  render(){ 
  let pathName = window.location.pathname;
  const currentPath = pathName.replace(/\/wp/g, ""); 
  let renderComponent = this.renderSwitch(currentPath);  
  return (
    <div>
    {renderComponent}
    </div>
  );
    
}

}



