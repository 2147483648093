import React, { Component } from 'react'

import MainContent from '../../containers/main/MainContent.js';

export default class ContactsWrap extends Component {

    render(){

        return(
<div className="main">
<MainContent />
</div>
        )     
    }    

}

