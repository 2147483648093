import React, { Component } from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { reciveMain } from '../../actions/main/main.js'

import App from '../../components/main/App';

import { wrapContainer } from '../../containers/wrap-containers';









class MainContent extends Component {
  constructor() {
    super();

    this.state = {
      mainInfo: [],
      titlePage: '', 
      isLoaded: false
    };

  }

  componentDidMount() {

    fetch("https://blog.vorzelski.com.ua/wp/wp-json/wp-rest-api-sidebars/v1/sidebars/header")
      .then(res => res.json())
      .then(
        (result) => {
       // console.log(result.widgets[1]);
        },
        (error) => {
         console.log(error);
        }
      )


   const { ApplicationService } = this.props;
   ApplicationService.getMainInfo().then((body) => {
    this.props.reciveMain(body)    
     this.setState({
       isLoaded: true,
       mainInfo: this.props.mainContent,
       titlePage: body[0].title.rendered  
     }) 
          });
   
      }	 
          
          


    render(){
let { mainInfo, titlePage, isLoaded } = this.state;
 
        return(
          
          <div>
<App main={mainInfo} mainTitle={titlePage}/>
</div>

        )     
    }    

}

const mapStateProps = (state) => {
    return{
      mainContent: state.mainReducer
    } 
    }
    const mapDispatchProps = (dispatch) =>{  
    return {
    reciveMain: bindActionCreators (reciveMain, dispatch)
      }
    }
    
    export default wrapContainer()(connect(mapStateProps, mapDispatchProps)(MainContent))