import React, { Component } from 'react'
import Parser from 'html-react-parser';
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom'; 

import './ContactsInfo.scss';

export default class ContactsInfo extends Component {


  
  render(){
    const contactsInfo = this.props.contacts;
let title = this.props.contactsTitle; 

const currentRoute= window.location.pathname;
console.log(currentRoute);

    return(    
    <div className="page-info">
<MetaTags>
    <title>{title}</title>
    <meta name="description" content={`${title} - email, contact form`} />
    <meta property="og:title" content={title} />
    <meta property="og:image" content="" />
    </MetaTags> 

{contactsInfo.map(contact => {
return <div key={contact.id}>
  <h2>{contact.title.rendered}</h2>
  <div>{Parser(String(contact.content.rendered))}</div>
</div>
})

  }


    </div>
          )     
  }    
}

