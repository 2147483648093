import React, { Component } from 'react'
import Parser from 'html-react-parser';
import { Link } from 'react-router-dom'; 

import './MenuInfo.scss';

export default class MenuInfo extends Component {
 
  constructor(props) {
    super(props);
    this.state = {
    currentId: 0
    };
  } 
  toggleOver = (id) => { 
    this.setState({ 
    currentId: id
    });
  } 
  toggleOut = () => { 
    this.setState({ 
    currentId: 0
    });
  }

  render(){

const menuInfo = this.props.menu;
const { currentId } = this.state;

    return(    
    <div className="menu">
 <ul className="menu__ul">     
      { menuInfo.map(menu => { 
 if(menu.children){       
  return <li key={menu.id} onMouseOut={this.toggleOut.bind(this)} onMouseOver={this.toggleOver.bind(this, menu.id)} className={currentId === menu.id  ? 'toggle-button show' : 'toggle-button' }><a href={menu.url}>{menu.title}</a>
  <ul className="sub-menu" className={currentId === menu.id  ? 'sub-menu show' : 'sub-menu' }>
 {menu.children.map(menuchild => {
return <li key={menuchild.id}><a href={menuchild.url}>{menuchild.title}</a></li>
 })} 
 </ul>
  </li> 
 }else{
  return <li key={menu.id}><a href={menu.url}>{menu.title}</a></li>
 }      
})
  }
</ul>
    </div>
          );     
  }    
}

