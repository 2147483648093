 import constants from '../keymirror/index.js'



const initialState = [];
 

const pagesReducer = (state = initialState, action) => {

if(action.type === constants.RECIVE_PAGES){
    return state.innerHTML = action.pages
}	

return state

}



export default pagesReducer