import Api from '../api/api.js'
let apiInfo = new Api();
const serverLink = apiInfo._serverLink; 


export default class ApplicationService {

  
  async getResource(url){
    const res = await fetch(`${serverLink}${url}`);
    
  if(!res.ok){
    throw new Error(res.status)
  }
  return await res.json();
  } 
getOneNews(){ 
  return this.getResource(`/wp-json/wp/v2/posts?slug=contacts`)
} 

getMainInfo(){ 
  return this.getResource(`/wp-json/wp/v2/pages?slug=vorzel-about`)
}
getPagesInfo(slug){ 
  return this.getResource(`/wp-json/wp/v2/pages?slug=${slug}`)
}  

getMenu(){ 
  return this.getResource(`/wp-json/wp-api-menus/v2/menus/2`)
} 


  
sendQuestion(userName, userEmail, userMessage){
  let dataS = {
    "name": userName,
    "email": userEmail,
    "text": userMessage  
  }
  console.log(dataS);
  
    return fetch(`${serverLink}/wp-json/send-contact-form/v1/contact`, {
      headers: { 'Content-Type': 'application/json'},
      method: 'POST',
      
       body: JSON.stringify({
            "name": userName,
            "email": userEmail,
            "text": userMessage
          }),
      
      mode: 'cors'
      
      })
        .then(response => response)
        .then(json => { 
  
          }).catch((error) => {
            console.log(error);
          });   
  
}

}
